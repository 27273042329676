import { ExpiredUnit, StorageUtil } from './util';

interface ICache {
  [key: string]: boolean;
}
const cache: ICache = {};

function WebStorage(
  storage: Storage,
  key: string,
  expiredAt: number = 0,
  expiredUnit: ExpiredUnit = 'd',
) {
  return (target: Object, propertyName: string): void => {
    key = key || propertyName;
    Object.defineProperty(target, propertyName, {
      get: () => {
        return StorageUtil.get(storage, <string>key);
      },
      set: (value: any) => {
        if (!cache[<string>key]) {
          const storedValue = StorageUtil.get(storage, <string>key);
          if (storedValue === null) {
            StorageUtil.set(
              storage,
              <string>key,
              value,
              expiredAt,
              expiredUnit,
            );
          }
          cache[<string>key] = true;
          return;
        }
        StorageUtil.set(storage, <string>key, value, expiredAt, expiredUnit);
      },
      enumerable: true,
      configurable: true,
    });
  };
}

/**
 * localStorage è£é¥°å¨
 *
 * @param [key] æå®ä¸ä¸ªæ°key
 * @param [expiredAt=0] è¿ææ¶é´å¼ï¼0è¡¨ç¤ºæ°¸ä¹ææã
 * @param [expiredUnit='t'] è¿ææ¶é´åä½ï¼é»è®¤ï¼èªå®ä¹[åä½ï¼æ¯«ç§]ï¼
 */
export function LocalStorage(
  key?: string,
  expiredAt: number = 0,
  expiredUnit: ExpiredUnit = 't',
) {
  return WebStorage(localStorage, key, expiredAt, expiredUnit);
}

/**
 * sessionStorage è£é¥°å¨
 *
 * @param [key] æå®ä¸ä¸ªæ°key
 * @param [expiredAt=0] è¿ææ¶é´å¼ï¼0è¡¨ç¤ºå¨ `sessionStorage` æææ¶æèå´åæ°¸ä¹ææã
 * @param [expiredUnit='t'] è¿ææ¶é´åä½ï¼é»è®¤ï¼èªå®ä¹[åä½ï¼æ¯«ç§]ï¼
 */
export function SessionStorage(
  key?: string,
  expiredAt: number = 0,
  expiredUnit: ExpiredUnit = 't',
) {
  return WebStorage(sessionStorage, key, expiredAt, expiredUnit);
}
